
import { defineComponent, onMounted, onUpdated, ref } from "vue";
import { Form } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import TableBasic from "@/components/tables/TableBasic.vue";
import { ElNotification } from "element-plus";
import { VueDraggableNext } from "vue-draggable-next";

interface FormMaritimeRoute {
  maritime_service_id: "";
  location_id: "";
  order: "";
}

export default defineComponent({
  name: "MaritimeRouteForm",
  components: {
    Form,
    TableBasic,
    draggable: VueDraggableNext,
  },
  props: {
    entityId: String,
  },

  setup(props) {
    const { t } = useI18n();
    const isLoading = ref(true);
    const addRoutes = ref(false);
    const addFirst = ref(true);
    const submitButton = ref<HTMLElement | null>(null);
    const tableData = ref<[] | any>([]);
    const dataObject = ref([]);
    const inputLocation = ref({
      loading: false,
      options: [],
      list: [],
    });
    const header = ref([{ column: t("location_type"), key: "name" }]);

    const getLocation = () => {
      ApiService.query(`/api/location/lists`, {
        params: { per_page: 25, is_maritime: true },
      }).then(({ data }) => {
        const temp = data.locations.filter((x) => x.maritime === 1);
        inputLocation.value.list = temp;
        inputLocation.value.options = temp;
      });
    };
    const selectLocation = (query) => {
      if (query !== "") {
        inputLocation.value.loading = true;
        setTimeout(() => {
          inputLocation.value.loading = false;
          inputLocation.value.options = inputLocation.value.list.filter(
            (item: Record<string, any>) => {
              return (
                item.full_name.toLowerCase().indexOf(query.toLowerCase()) > -1
              );
            }
          );
          if (!inputLocation.value.options.length) {
            ApiService.query(`/api/location/lists`, {
              params: { per_page: 25, name: query, is_maritime: true },
            }).then(({ data }) => {
              const temp = data.locations.filter((x) => x.maritime === 1);
              inputLocation.value.list = temp;
              inputLocation.value.options = temp;
            });
          }
        }, 200);
      } else {
        inputLocation.value.options = [];
      }
    };

    const createElement = () => {
      addRoutes.value = true;
      if (tableData.value.length > 0) {
        let invalidTable = false;
        tableData.value.forEach((item, index) => {
          if (index === tableData.value.length - 1) {
            invalidTable = item.location_id === "";
          }
        });
        if (!invalidTable) {
          addLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rmteditabletable"),
            type: "error",
          });
        }
      } else {
        addLine();
      }
    };

    const addLine = () => {
      if (!addFirst.value || !dataObject.value.length) {
        tableData.value.push({
          maritime_service_id: props.entityId,
          location_id: "",
          order: tableData.value.length + 1,
        });
      } else if (dataObject.value.length) {
        dataObject.value.forEach((item: any) => {
          tableData.value.push({
            maritime_service_id: props.entityId,
            location_id: item.id,
            order: tableData.value.length + 1,
          });
        });
        addFirst.value = false;
      }
    };

    const removeElement = (index) => {
      tableData.value.splice(index, 1);
    };

    const updateTable = () => {
      ApiService.get(`/api/maritime-service/${props.entityId}/route`).then(
        ({ data }) => {
          isLoading.value = false;
          dataObject.value = data.route;
        }
      );
    };

    onUpdated(() => {
      console.clear();
    });

    onMounted(() => {
      console.clear();
      getLocation();
      if (props.entityId) {
        updateTable();
      }
    });

    const onSumbit = (value, { resetForm }) => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");

        tableData.value.forEach((item: any, index) => {
          item.order = index + 1;
        });

        ApiService.post(`api/maritime-service/${props.entityId}/route`, {
          locations: tableData.value,
        })
          .then(({ data }) => {
            submitButton.value?.removeAttribute("data-kt-indicator");
            dataObject.value = [];
            updateTable();
            addRoutes.value = false;
          })
          .catch(() => {
            submitButton.value?.removeAttribute("data-kt-indicator");
          });
      }
    };

    return {
      isLoading,
      submitButton,
      tableData,
      dataObject,
      inputLocation,
      header,
      addRoutes,
      createElement,
      removeElement,
      onSumbit,
      selectLocation,
    };
  },
});
